import { Button } from '@datasnipper/ui/button'
import { Link } from '@remix-run/react'

import { Footer, FooterNavigationItem } from '#app/components/footer/footer.tsx'
import { SubMenuItem } from '#app/components/navigation/navigation.tsx'
import {
  Link as LinkType,
  Maybe,
  type Footer as FooterModel,
} from '#app/graphql/@generated/api.ts'

// TODO: this is duplicated from navigation, we should reuse it
function mapSubMenuItems(items: Maybe<LinkType>[]): SubMenuItem[] {
  return (items ?? []).map(item => ({
    label: item?.title ?? '',
    href: item?.href ?? '',
  }))
}

function mapItems(items: FooterModel['items']): FooterNavigationItem[] {
  return (items ?? []).map(
    item =>
      item?.sub_menu_item?.map(subItem => ({
        title: subItem?.title ?? '',
        items: mapSubMenuItems(subItem?.items ?? []),
      })) ?? [],
  )
}

export function CsFooter({ entry }: { entry: FooterModel | null | undefined }) {
  if (!entry) {
    return null
  }

  return (
    <Footer
      title={entry.main_title ?? ''}
      subtitle={entry.subtitle ?? ''}
      items={mapItems(entry.items)}
      logo={{
        src: entry.logoConnection?.edges?.[0]?.node?.url ?? '',
        alt:
          entry.logoConnection?.edges?.[0]?.node?.description ??
          entry.logoConnection?.edges?.[0]?.node?.title ??
          '',
      }}
      backgroundImage={
        entry.background_imageConnection?.edges?.[0]?.node?.url ?? ''
      }
      backgroundImageSmall={
        entry.background_image_smallConnection?.edges?.[0]?.node?.url ?? ''
      }
      primaryActionSlot={
        <Link reloadDocument to={entry.primary_button?.href ?? '#'}>
          <Button className="w-full">
            {entry.primary_button?.title ?? ''}
          </Button>
        </Link>
      }
      secondaryActionSlot={
        <Link reloadDocument to={entry.secondary_button?.href ?? '#'}>
          <Button
            variant="outline"
            className="w-full border-primary-foreground text-primary-foreground hover:bg-primary-foreground/10"
          >
            {entry.secondary_button?.title ?? ''}
          </Button>
        </Link>
      }
      securityLogos={entry.security_logosConnection?.edges?.map(edge => ({
        alt: edge?.node?.description ?? edge?.node?.title ?? '',
        src: edge?.node?.url ?? '',
      }))}
      securityLinks={
        entry.security_links?.map(link => ({
          href: link?.href ?? '',
          children: link?.title ?? '',
        })) ?? []
      }
      socials={
        entry.socials?.map(social => ({
          icon: social?.title?.toLowerCase() ?? '',
          url: social?.href ?? '',
        })) ?? []
      }
    />
  )
}
