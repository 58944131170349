import { Icon } from '@datasnipper/icons'
import { cn } from '@datasnipper/utils/cn'

import { LinkButton } from '../link-button/link-button'

import { LinkType } from '#types/shared'

const bgClass = {
  neutral: 'bg-neutral',
  action: 'bg-action-100',
  highlight: 'bg-highlight-50',
  ['neutral-25']: 'bg-neutral-25',
  ['neutral-50']: 'bg-neutral-50',
}

export type AnnouncementBannerProps = {
  link: LinkType
  message: string
  background?: keyof typeof bgClass
  slot?: JSX.Element
  onHideAnnouncement?: () => void
}

export function AnnouncementBanner({
  background = 'neutral-25',
  link,
  message,
  slot,
  onHideAnnouncement,
}: AnnouncementBannerProps) {
  return (
    <div>
      <div className={cn('flex justify-center', bgClass[background])}>
        <div className="container h-12">
          <div className="flex items-center justify-center gap-4">
            <div>{slot}</div>
            <span className="w-auto overflow-hidden text-ellipsis text-nowrap break-keep text-sm font-medium text-neutral-800">
              {message}
            </span>
            <div className="pr-2">
              <LinkButton link={link} />
            </div>
          </div>
        </div>
      </div>
      {onHideAnnouncement && (
        <span>
          <Icon
            className="absolute right-3 top-4 cursor-pointer"
            onClick={onHideAnnouncement}
            name="cross"
            size="md"
          />
        </span>
      )}
    </div>
  )
}
