import { Badge } from '@datasnipper/ui/badge'
import { cn } from '@datasnipper/utils/cn'
import { Link } from '@remix-run/react'

import { MenuItemType, SubMenuItem } from '../../navigation'

import { CsComponent } from '#app/contentstack/component.js'
import { isEmpty } from '#app/utils/misc.js'

export type MenuItemProps = {
  items: MenuItemType[]
  singleColumn?: boolean
}

export function MenuItem({ items, singleColumn }: MenuItemProps) {
  return (
    <div className="grid auto-cols-max grid-flow-col">
      {items.map((item, index) => {
        const hasTitle = !!item.title

        return (
          <div
            key={index}
            className={cn(
              'items-center border-neutral-50 px-5 last:border-none',
              singleColumn || items.length === 1
                ? 'col-span-full border-none'
                : 'col-span-1 border-r',
              hasTitle ? 'my-3 pt-2' : 'pt-0',
            )}
          >
            {hasTitle && <h3 className="text-xs text-muted">{item.title}</h3>}

            <ul className="w-[calc(100vw*0.75)] md:w-auto">
              {item.items?.map((subItem, subItemIndex) => {
                if (subItem.component)
                  return (
                    <li key={subItemIndex}>
                      <CsComponent entry={subItem.component} preview={false} />
                    </li>
                  )

                return isEmpty(subItem.items) ? (
                  renderLink(subItem, hasTitle)
                ) : (
                  <li key={subItem.title} className="pr-5">
                    <h3 className="text-xs text-muted">{subItem.title}</h3>
                    <ul>
                      {subItem.items?.map(nestedSubItem =>
                        renderLink(nestedSubItem, false),
                      )}
                    </ul>
                  </li>
                )
              })}
            </ul>
          </div>
        )
      })}
    </div>
  )
}

const renderLink = (subItem: SubMenuItem, hasTitle: boolean) => (
  <Link
    reloadDocument
    key={subItem.title}
    to={subItem.href}
    className="m-0 px-4 text-sm active:text-accent"
    prefetch="intent"
  >
    <li
      key={subItem.title}
      className={cn(
        'flex items-center justify-between gap-12 pl-0 group-first:pl-0',
        !hasTitle && 'first:mt-0 first:pt-0 last:mt-0 last:pt-0',
      )}
    >
      <div className="flex gap-4 hover:text-muted">
        {!isEmpty(subItem.icon) && (
          <img
            src={subItem.icon?.src}
            alt={subItem.icon?.alt}
            className="size-6"
          />
        )}
        <span className="mr-2">{subItem.title}</span>
      </div>

      {subItem.badge && (
        <Badge className="rounded-full bg-highlight-500 px-3 py-2">
          <h5 className="text-xs font-semibold text-neutral-100">
            {subItem.badge.toUpperCase()}
          </h5>
        </Badge>
      )}
    </li>
  </Link>
)
