export function LogoInverse({ className = '' }: { className?: string }) {
  return (
    <svg
      className={className}
      width="494"
      height="66"
      viewBox="0 0 494 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_860_664)">
        <path
          d="M446.349 15.7826C434.651 15.7826 426.446 23.8234 426.446 35.1956C426.446 46.5678 435.707 54.6086 448.217 54.6086C451.466 54.6086 455.367 54.3652 458.29 53.0662C458.859 52.8228 459.264 52.4151 459.428 51.8462L461.622 44.5355C461.947 43.4798 461.378 42.993 460.402 43.4798C456.666 45.4299 453.334 46.2422 449.112 46.2422C442.939 46.2422 438.065 43.8053 437.17 37.8759H463.407C464.789 37.8759 465.114 37.0636 465.114 35.9257C465.114 23.0143 458.047 15.7826 446.349 15.7826ZM437.414 31.0551C438.469 26.5068 441.557 23.9056 446.188 23.9056C450.493 23.9056 453.742 26.9936 454.067 31.0551H437.414Z"
          fill="#FEFDFF"
        />
        <path
          d="M127.036 1H118.913C118.183 1 117.697 1.48675 117.697 2.21687V22.9321C115.016 18.3017 110.547 15.7826 104.943 15.7826C94.4655 15.7826 86.9904 23.8234 86.9904 35.1956C86.9904 46.5678 94.4623 54.6086 104.943 54.6086C110.547 54.6086 115.016 52.1717 117.697 47.4591V52.4151C117.697 53.1452 118.183 53.6351 118.913 53.6351H127.036C127.767 53.6351 128.253 53.1483 128.253 52.4151V2.21687C128.256 1.48675 127.767 1 127.036 1ZM108.028 45.4331C102.016 45.4331 97.8727 41.2104 97.8727 35.1987C97.8727 29.1871 102.016 24.9644 108.028 24.9644C113.632 24.9644 117.693 29.4336 117.693 35.1987C117.693 40.9638 113.632 45.4331 108.028 45.4331Z"
          fill="#FEFDFF"
        />
        <path
          d="M172.117 16.7593H163.994C163.264 16.7593 162.778 17.246 162.778 17.9793V22.9353C160.097 18.3048 155.628 15.7858 150.024 15.7858C139.546 15.7858 132.071 23.8266 132.071 35.1988C132.071 46.571 139.543 54.6118 150.024 54.6118C155.628 54.6118 160.097 52.1749 162.778 47.4623V52.4183C162.778 53.1484 163.264 53.6383 163.994 53.6383H172.117C172.848 53.6383 173.334 53.1515 173.334 52.4183V17.9761C173.337 17.246 172.851 16.7593 172.117 16.7593ZM153.112 45.4331C147.101 45.4331 142.957 41.2104 142.957 35.1988C142.957 29.1871 147.101 24.9644 153.112 24.9644C158.716 24.9644 162.778 29.4337 162.778 35.1988C162.778 40.9639 158.716 45.4331 153.112 45.4331Z"
          fill="#FEFDFF"
        />
        <path
          d="M183.815 52.4182V25.6945H177.317C176.422 25.6945 176.097 25.1255 176.343 24.3132L178.294 17.7327C178.537 17.0026 179.024 16.7592 179.675 16.7592H183.818V7.90291C183.818 7.17278 184.305 6.68604 185.035 6.68604H193.158C193.888 6.68604 194.375 7.17278 194.375 7.90291V16.756H200.874C201.768 16.756 202.09 17.3249 201.847 18.1372L199.818 24.7178C199.574 25.4479 199.088 25.6913 198.437 25.6913H194.375V52.415C194.375 53.1451 193.888 53.635 193.158 53.635H185.035C184.302 53.6382 183.815 53.1483 183.815 52.4182Z"
          fill="#FEFDFF"
        />
        <path
          d="M240.676 16.7593H232.553C231.823 16.7593 231.336 17.246 231.336 17.9793V22.9353C228.656 18.3048 224.187 15.7858 218.583 15.7858C208.105 15.7858 200.63 23.8266 200.63 35.1988C200.63 46.571 208.102 54.6118 218.583 54.6118C224.187 54.6118 228.656 52.1749 231.336 47.4623V52.4183C231.336 53.1484 231.823 53.6383 232.553 53.6383H240.676C241.406 53.6383 241.893 53.1515 241.893 52.4183V17.9761C241.896 17.246 241.406 16.7593 240.676 16.7593ZM221.668 45.4331C215.656 45.4331 211.512 41.2104 211.512 35.1988C211.512 29.1871 215.656 24.9644 221.668 24.9644C227.272 24.9644 231.333 29.4337 231.333 35.1988C231.333 40.9639 227.272 45.4331 221.668 45.4331Z"
          fill="#FEFDFF"
        />
        <path
          d="M245.632 51.6881C244.981 51.2804 244.82 50.7146 244.981 50.0635L246.931 43.4829C247.175 42.6706 247.743 42.4273 248.556 42.914C252.535 45.2687 256.028 46.2454 259.928 46.2454C263.907 46.2454 266.019 45.2719 266.019 42.914C266.019 40.395 263.989 40.0694 256.758 38.2836C248.96 36.3334 245.872 33.571 245.872 27.3982C245.872 20.2487 251.884 15.7826 261.632 15.7826C267.074 15.7826 271.866 16.8383 275.605 18.7062C276.256 19.114 276.5 19.6797 276.256 20.3308L274.227 26.9114C273.984 27.7237 273.415 27.9671 272.602 27.4803C268.623 25.1256 265.209 24.149 261.312 24.149C258.145 24.149 256.439 25.2046 256.439 27.1548C256.439 29.3483 258.224 29.9994 264.887 31.703C273.171 33.8144 276.585 36.738 276.585 42.5885C276.585 49.9813 270.491 54.6118 260.095 54.6118C254.16 54.6118 249.368 53.5561 245.632 51.6881Z"
          fill="#FEFDFF"
        />
        <path
          d="M280.314 52.4182V17.9761C280.314 17.246 280.801 16.7561 281.534 16.7561H289.657C290.388 16.7561 290.878 17.2428 290.878 17.9761V22.0376C293.153 17.8149 296.889 15.7826 302.168 15.7826C311.021 15.7826 315.326 21.0641 315.326 30.0784V52.4182C315.326 53.1484 314.839 53.6383 314.109 53.6383H305.986C305.256 53.6383 304.766 53.1515 304.766 52.4182V32.0286C304.766 27.0726 302.572 24.3923 298.267 24.3923C293.801 24.3923 290.874 27.237 290.874 32.0286V52.4182C290.874 53.1484 290.388 53.6383 289.654 53.6383H281.531C280.801 53.6383 280.314 53.1484 280.314 52.4182Z"
          fill="#FEFDFF"
        />
        <path
          d="M320.847 52.4182V17.9761C320.847 17.2459 321.334 16.756 322.067 16.756H330.19C330.92 16.756 331.407 17.2428 331.407 17.9761V52.4182C331.407 53.1483 330.92 53.6382 330.19 53.6382H322.067C321.337 53.6382 320.847 53.1483 320.847 52.4182ZM321.663 9.69188L323.856 2.46337C324.1 1.73325 324.586 1.48987 325.237 1.48987H333.278C334.173 1.48987 334.498 2.05879 334.252 2.87109L332.058 10.0996C331.815 10.8297 331.328 11.0731 330.677 11.0731H322.636C321.742 11.0731 321.416 10.5042 321.663 9.69188Z"
          fill="#FEFDFF"
        />
        <path
          d="M360.571 15.7826C354.967 15.7826 350.498 18.2195 347.817 22.9321V17.9761C347.817 17.246 347.331 16.7561 346.597 16.7561H338.474C337.744 16.7561 337.254 17.2428 337.254 17.9761V65.0895C337.254 65.8196 337.741 66.3095 338.474 66.3095H346.597C347.328 66.3095 347.817 65.8228 347.817 65.0895V47.4623C350.498 52.0927 354.967 54.6118 360.571 54.6118C371.049 54.6118 378.524 46.5709 378.524 35.1988C378.524 23.8266 371.049 15.7826 360.571 15.7826ZM357.483 45.4331C351.879 45.4331 347.817 40.9639 347.817 35.1988C347.817 29.4336 351.879 24.9644 357.483 24.9644C363.495 24.9644 367.638 29.1871 367.638 35.1988C367.638 41.2104 363.495 45.4331 357.483 45.4331Z"
          fill="#FEFDFF"
        />
        <path
          d="M405.652 15.7826C400.048 15.7826 395.579 18.2195 392.899 22.9321V17.9761C392.899 17.246 392.412 16.7561 391.678 16.7561H383.555C382.825 16.7561 382.335 17.2428 382.335 17.9761V65.0895C382.335 65.8196 382.822 66.3095 383.555 66.3095H391.678C392.409 66.3095 392.899 65.8228 392.899 65.0895V47.4623C395.579 52.0927 400.048 54.6118 405.652 54.6118C416.13 54.6118 423.605 46.5709 423.605 35.1988C423.605 23.8266 416.13 15.7826 405.652 15.7826ZM402.564 45.4331C396.96 45.4331 392.899 40.9639 392.899 35.1988C392.899 29.4336 396.96 24.9644 402.564 24.9644C408.576 24.9644 412.719 29.1871 412.719 35.1988C412.719 41.2104 408.576 45.4331 402.564 45.4331Z"
          fill="#FEFDFF"
        />
        <path
          d="M468.686 52.4182V17.9761C468.686 17.2459 469.172 16.756 469.906 16.756H478.029C478.759 16.756 479.249 17.2428 479.249 17.9761V23.3366C481.035 18.624 484.53 15.9437 489.401 15.9437C490.295 15.9437 491.43 16.0259 492.246 16.3515C493.14 16.677 493.301 17.2459 493.058 17.9761L490.946 24.8C490.703 25.5301 490.134 25.7735 489.404 25.4511C488.51 25.1256 487.697 24.9643 486.642 24.9643C482.823 24.9643 479.249 27.4834 479.249 34.3074V52.4213C479.249 53.1514 478.762 53.6414 478.029 53.6414H469.906C469.172 53.6382 468.686 53.1483 468.686 52.4182Z"
          fill="#FEFDFF"
        />
        <path
          d="M65.6494 1.0127H47.3268C46.6725 1.0127 46.0941 1.43939 45.9013 2.06837L40.5819 19.4743C40.3417 20.2582 39.621 20.7923 38.8024 20.7923H24.7784C24.1241 20.7923 23.5457 21.219 23.3529 21.8448L18.811 36.697C18.5708 37.4776 17.8501 38.0118 17.0315 38.0118H5.50761C4.85335 38.0118 4.27494 38.4385 4.08213 39.0675L0.0427627 52.2792C-0.146879 52.905 0.317744 53.5372 0.972009 53.5372H13.5137C14.1521 53.5372 14.7147 53.1199 14.9012 52.5099L18.6846 40.1358C18.9026 39.4246 19.5569 38.9411 20.2997 38.9411H34.4786C35.117 38.9411 35.6796 38.5238 35.8661 37.9138L40.4523 22.9132C40.6704 22.202 41.3246 21.7184 42.0674 21.7184H59.6662C60.3046 21.7184 60.8672 21.3012 61.0537 20.6912L66.6608 2.37811C66.8662 1.69856 66.3605 1.0127 65.6494 1.0127Z"
          fill="#FEFDFF"
        />
        <path
          d="M29.1212 53.5372H20.8591C20.2048 53.5372 19.7402 52.905 19.9298 52.2824L22.1739 44.9369C22.3667 44.3111 22.942 43.8844 23.5963 43.8844H31.8299C32.4841 43.8844 32.9488 44.5165 32.7591 45.1392L30.5055 52.5099C30.3191 53.1199 29.7565 53.5372 29.1212 53.5372Z"
          fill="#FEFDFF"
        />
        <path
          d="M49.0431 53.5372H37.3516C36.6974 53.5372 36.2328 52.905 36.4224 52.2824L38.6665 44.9369C38.8593 44.3111 39.4345 43.8844 40.0888 43.8844H51.7518C52.406 43.8844 52.8707 44.5165 52.681 45.1392L50.4275 52.5099C50.2441 53.1199 49.6815 53.5372 49.0431 53.5372Z"
          fill="#FEFDFF"
        />
        <path
          d="M53.4807 38.9379H41.8145C41.1603 38.9379 40.6957 38.3057 40.8853 37.6831L43.9322 27.7143C44.125 27.0884 44.7002 26.6617 45.3545 26.6617H56.9922C57.6465 26.6617 58.1111 27.2939 57.9214 27.9166L54.8651 37.9138C54.6817 38.5239 54.1191 38.9379 53.4807 38.9379Z"
          fill="#FEFDFF"
        />
      </g>
      <defs>
        <clipPath id="clip0_860_664">
          <rect width="493.16" height="65.3101" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
