import { LogoInverse } from '@datasnipper/ui/logo-inverse'
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuList,
  NavigationMenuTrigger,
} from '@datasnipper/ui/navigation-menu'
import { cn } from '@datasnipper/utils/cn'
import { Link, useLocation } from '@remix-run/react'
import { useMotionValueEvent, useScroll } from 'framer-motion'
import { useState } from 'react'

import { MenuItem } from './components/menu-item/menu-item'
import { MobileMenu } from './components/mobile-menu/mobile-menu'

import { Link as LinkType } from '#app/graphql/@generated/api.ts'
import { ImageAttributes } from '#types/shared.ts'

export type SubMenuItem = {
  label: string
  href: string
  title?: string
  icon?: ImageAttributes
  badge?: string
  items?: SubMenuItem[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component?: any
}

export type MenuItem = {
  title: string
  link?: LinkType | undefined
  singleColumn: boolean
  subMenuItem: SubMenuItem[]
}

export interface MenuItemType extends SubMenuItem {
  items?: SubMenuItem[]
}

export type NavigationItem = {
  title: string
  href?: string
  items?: MenuItemType[]
  variant?: string
}

type Props = {
  actionButton: JSX.Element
  items: MenuItem[]
  className?: string
}

export function Navigation({ actionButton, items, className }: Props) {
  const location = useLocation()

  const isHomePage = location.pathname === '/'

  // Currently we only want a transparent bg for the home page, maybe in the future we will make it a parameter
  const defaultBackground = isHomePage ? 'bg-transparent' : 'bg-neutral-800'

  const [isAtTopOfPage, setIsAtTopOfPage] = useState(true)
  const { scrollY } = useScroll()

  useMotionValueEvent(scrollY, 'change', latest =>
    setIsAtTopOfPage(latest === 0),
  )

  return (
    <nav
      className={cn(
        className,
        isAtTopOfPage ? defaultBackground : `top-0 bg-neutral-800`,
        'fixed inset-x-0 z-50 flex justify-center',
        `text-primary-foreground transition-none`,
      )}
    >
      <div className="container py-4">
        <div className="mx-0 flex justify-between">
          <Link to="/" title="Home">
            <LogoInverse className="w-40 pl-2.5 sm:pl-0" />
            <span className="sr-only">Home</span>
          </Link>
          <div className="flex items-center gap-x-12">
            <NavigationMenu className="hidden text-sm md:block lg:text-lg">
              <NavigationMenuList className="flex items-center">
                {items.map((item, index) => (
                  <NavigationMenuItem key={index}>
                    {item.link?.href ? (
                      <Link
                        reloadDocument
                        to={item.link.href}
                        prefetch="intent"
                        className="flex items-center"
                      >
                        <span className="px-2 font-display text-base hover:text-accent lg:px-3">
                          {item.link.title}
                        </span>
                      </Link>
                    ) : (
                      <div className="w-full">
                        <NavigationMenuTrigger
                          className={cn(
                            'bg-transparent px-2 text-base font-normal outline-none lg:px-3',
                            'hover:bg-transparent hover:text-accent',
                            'focus:bg-transparent focus:text-current',
                            'data-[active]:bg-transparent data-[state=open]:bg-transparent',
                          )}
                        >
                          {item.title}
                        </NavigationMenuTrigger>
                        <NavigationMenuContent
                          className={cn(
                            'absolute left-0',
                            item.singleColumn
                              ? '-translate-x-32'
                              : '-translate-x-96',
                          )}
                        >
                          {/* Custom NavigationMenuIndicator*/}
                          <div
                            className={cn(
                              'absolute left-10 size-5 h-[1.5] -translate-y-1.5  rotate-45 rounded-tl-sm bg-white ',
                              item.singleColumn
                                ? 'translate-x-32'
                                : 'translate-x-96',
                            )}
                          />
                          <MenuItem
                            items={item.subMenuItem}
                            singleColumn={item.singleColumn}
                          />
                        </NavigationMenuContent>
                      </div>
                    )}
                  </NavigationMenuItem>
                ))}
              </NavigationMenuList>
            </NavigationMenu>

            <span className="hidden w-fit lg:block">{actionButton}</span>

            <div className="block md:hidden">
              <MobileMenu actionButton={actionButton} items={items} />
            </div>
          </div>
        </div>
      </div>
    </nav>
  )
}
