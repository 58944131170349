import { Icon } from '@datasnipper/icons'
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from '@datasnipper/ui/collapsible'
import { ScrollArea } from '@datasnipper/ui/scroll-area'
import {
  Sheet,
  SheetContent,
  SheetTrigger,
  SheetClose,
} from '@datasnipper/ui/sheet'
import { Link } from '@remix-run/react'

import { MenuItem as MenuItemType } from '../../navigation'
import { MenuItem } from '../menu-item/menu-item'

import { isEmpty } from '#app/utils/misc.js'

type Props = {
  actionButton: JSX.Element
  items: MenuItemType[]
}

export function MobileMenu({ actionButton, items }: Props) {
  return (
    <Sheet>
      <SheetTrigger asChild>
        <button
          aria-label="Toggle navigation menu"
          className="size-10 pr-5 sm:pr-0"
        >
          <Icon className="size-8" name="menu" aria-hidden="true" />
          <span className="sr-only">Toggle navigation menu</span>
        </button>
      </SheetTrigger>

      <SheetClose aria-label="Close navigation menu" className="opacity-100" />

      <SheetContent side="full" className="overflow-y-auto">
        <div className="mt-20 flex justify-center">
          <div className="w-screen">
            <nav className="grid gap-8 text-lg font-bold">
              {actionButton}

              {items.map((item, index) => {
                // If the root item has a link, there is no sub-menu,
                // so we just render a single link. If the link is empty
                // and there is a sub menu, we render a collapsible menu.
                return isEmpty(item?.subMenuItem) ? (
                  item.link?.href ? (
                    <Link
                      className="text-base"
                      key={index}
                      reloadDocument
                      to={item.link.href}
                    >
                      {item.title}
                    </Link>
                  ) : null
                ) : (
                  <Collapsible key={index}>
                    <CollapsibleTrigger className="group flex w-full justify-between hover:text-accent">
                      <span className="text-base">{item.title}</span>
                      <Icon
                        className="transition-all group-data-[state=open]:rotate-90"
                        name="chevron-right"
                        size="lg"
                      />
                    </CollapsibleTrigger>
                    <ScrollArea className="mt-4 bg-neutral-25">
                      <CollapsibleContent className="mt-3 transition-all">
                        <MenuItem items={item.subMenuItem} singleColumn />
                      </CollapsibleContent>
                    </ScrollArea>
                  </Collapsible>
                )
              })}
            </nav>
          </div>
        </div>
      </SheetContent>
    </Sheet>
  )
}
