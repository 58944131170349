import { cn } from '@datasnipper/utils/cn'

export function Logo({
  className = '',
}: {
  className?: string
  height?: number
  width?: number
}) {
  return (
    <div className={cn(className)}>
      <svg viewBox="0 0 494 66" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_10_1067)">
          <path
            d="M446.769 15.865C435.071 15.865 426.866 23.9059 426.866 35.278C426.866 46.6502 436.127 54.6911 448.637 54.6911C451.886 54.6911 455.786 54.4477 458.71 53.1486C459.279 52.9053 459.684 52.4975 459.848 51.9286L462.041 44.6179C462.367 43.5622 461.798 43.0755 460.821 43.5622C457.085 45.5124 453.754 46.3247 449.531 46.3247C443.359 46.3247 438.485 43.8878 437.59 37.9583H463.827C465.208 37.9583 465.534 37.146 465.534 36.0082C465.534 23.0967 458.467 15.865 446.769 15.865ZM437.834 31.1375C438.889 26.5893 441.977 23.988 446.608 23.988C450.913 23.988 454.162 27.076 454.487 31.1375H437.834Z"
            fill="#011638"
          />
          <path
            d="M127.456 1.08246H119.333C118.603 1.08246 118.117 1.56921 118.117 2.29933V23.0145C115.436 18.3841 110.967 15.865 105.363 15.865C94.8854 15.865 87.4104 23.9058 87.4104 35.278C87.4104 46.6502 94.8823 54.691 105.363 54.691C110.967 54.691 115.436 52.2542 118.117 47.5416V52.4975C118.117 53.2276 118.603 53.7175 119.333 53.7175H127.456C128.187 53.7175 128.673 53.2308 128.673 52.4975V2.29933C128.676 1.56921 128.187 1.08246 127.456 1.08246ZM108.448 45.5155C102.436 45.5155 98.2927 41.2928 98.2927 35.2812C98.2927 29.2696 102.436 25.0469 108.448 25.0469C114.052 25.0469 118.113 29.5161 118.113 35.2812C118.113 41.0463 114.052 45.5155 108.448 45.5155Z"
            fill="#011638"
          />
          <path
            d="M172.537 16.8417H164.414C163.684 16.8417 163.198 17.3285 163.198 18.0617V23.0177C160.517 18.3873 156.048 15.8682 150.444 15.8682C139.966 15.8682 132.491 23.909 132.491 35.2812C132.491 46.6534 139.963 54.6942 150.444 54.6942C156.048 54.6942 160.517 52.2573 163.198 47.5447V52.5007C163.198 53.2308 163.684 53.7207 164.414 53.7207H172.537C173.268 53.7207 173.754 53.234 173.754 52.5007V18.0586C173.758 17.3284 173.271 16.8417 172.537 16.8417ZM153.532 45.5155C147.521 45.5155 143.377 41.2929 143.377 35.2812C143.377 29.2696 147.521 25.0469 153.532 25.0469C159.136 25.0469 163.198 29.5161 163.198 35.2812C163.198 41.0463 159.136 45.5155 153.532 45.5155Z"
            fill="#011638"
          />
          <path
            d="M184.235 52.5007V25.777H177.737C176.842 25.777 176.517 25.2081 176.763 24.3958L178.713 17.8152C178.957 17.0851 179.444 16.8417 180.095 16.8417H184.238V7.98542C184.238 7.2553 184.725 6.76855 185.455 6.76855H193.578C194.308 6.76855 194.795 7.2553 194.795 7.98542V16.8385H201.293C202.188 16.8385 202.51 17.4075 202.267 18.2198L200.238 24.8003C199.994 25.5305 199.508 25.7738 198.857 25.7738H194.795V52.4975C194.795 53.2276 194.308 53.7175 193.578 53.7175H185.455C184.722 53.7207 184.235 53.2308 184.235 52.5007Z"
            fill="#011638"
          />
          <path
            d="M241.096 16.8417H232.973C232.243 16.8417 231.756 17.3285 231.756 18.0617V23.0177C229.076 18.3873 224.607 15.8682 219.003 15.8682C208.525 15.8682 201.05 23.909 201.05 35.2812C201.05 46.6534 208.522 54.6942 219.003 54.6942C224.607 54.6942 229.076 52.2573 231.756 47.5447V52.5007C231.756 53.2308 232.243 53.7207 232.973 53.7207H241.096C241.826 53.7207 242.313 53.234 242.313 52.5007V18.0586C242.316 17.3284 241.826 16.8417 241.096 16.8417ZM222.088 45.5155C216.076 45.5155 211.932 41.2929 211.932 35.2812C211.932 29.2696 216.076 25.0469 222.088 25.0469C227.692 25.0469 231.753 29.5161 231.753 35.2812C231.753 41.0463 227.692 45.5155 222.088 45.5155Z"
            fill="#011638"
          />
          <path
            d="M246.052 51.7706C245.401 51.3628 245.24 50.7971 245.401 50.146L247.351 43.5654C247.595 42.7531 248.164 42.5097 248.976 42.9965C252.955 45.3512 256.448 46.3278 260.348 46.3278C264.327 46.3278 266.439 45.3544 266.439 42.9965C266.439 40.4774 264.41 40.1518 257.178 38.366C249.38 36.4159 246.292 33.6534 246.292 27.4806C246.292 20.3311 252.304 15.865 262.052 15.865C267.494 15.865 272.286 16.9207 276.025 18.7887C276.676 19.1964 276.92 19.7622 276.676 20.4133L274.647 26.9939C274.404 27.8062 273.835 28.0495 273.022 27.5628C269.043 25.2081 265.63 24.2314 261.732 24.2314C258.565 24.2314 256.859 25.2871 256.859 27.2372C256.859 29.4307 258.644 30.0819 265.307 31.7855C273.591 33.8968 277.005 36.8205 277.005 42.6709C277.005 50.0638 270.911 54.6942 260.516 54.6942C254.58 54.6942 249.788 53.6385 246.052 51.7706Z"
            fill="#011638"
          />
          <path
            d="M280.734 52.5007V18.0586C280.734 17.3284 281.221 16.8385 281.954 16.8385H290.077C290.808 16.8385 291.297 17.3253 291.297 18.0586V22.1201C293.573 17.8974 297.309 15.865 302.587 15.865C311.441 15.865 315.745 21.1466 315.745 30.1609V52.5007C315.745 53.2308 315.259 53.7207 314.529 53.7207H306.406C305.675 53.7207 305.186 53.234 305.186 52.5007V32.111C305.186 27.1551 302.992 24.4748 298.687 24.4748C294.221 24.4748 291.294 27.3194 291.294 32.111V52.5007C291.294 53.2308 290.808 53.7207 290.074 53.7207H281.951C281.221 53.7207 280.734 53.2308 280.734 52.5007Z"
            fill="#011638"
          />
          <path
            d="M321.267 52.5007V18.0586C321.267 17.3284 321.754 16.8385 322.487 16.8385H330.61C331.34 16.8385 331.827 17.3253 331.827 18.0586V52.5007C331.827 53.2308 331.34 53.7207 330.61 53.7207H322.487C321.757 53.7207 321.267 53.2308 321.267 52.5007ZM322.083 9.77438L324.276 2.54586C324.52 1.81574 325.006 1.57236 325.657 1.57236H333.698C334.593 1.57236 334.918 2.14129 334.672 2.95359L332.478 10.1821C332.235 10.9122 331.748 11.1556 331.097 11.1556H323.056C322.162 11.1556 321.836 10.5867 322.083 9.77438Z"
            fill="#011638"
          />
          <path
            d="M360.991 15.865C355.387 15.865 350.918 18.3019 348.238 23.0145V18.0586C348.238 17.3284 347.751 16.8385 347.017 16.8385H338.894C338.164 16.8385 337.674 17.3253 337.674 18.0586V65.1719C337.674 65.902 338.161 66.3919 338.894 66.3919H347.017C347.748 66.3919 348.238 65.9052 348.238 65.1719V47.5447C350.918 52.1751 355.387 54.6942 360.991 54.6942C371.469 54.6942 378.944 46.6534 378.944 35.2812C378.944 23.909 371.469 15.865 360.991 15.865ZM357.903 45.5155C352.299 45.5155 348.238 41.0463 348.238 35.2812C348.238 29.5161 352.299 25.0469 357.903 25.0469C363.915 25.0469 368.058 29.2696 368.058 35.2812C368.058 41.2929 363.915 45.5155 357.903 45.5155Z"
            fill="#011638"
          />
          <path
            d="M406.072 15.865C400.468 15.865 395.999 18.3019 393.318 23.0145V18.0586C393.318 17.3284 392.832 16.8385 392.098 16.8385H383.975C383.245 16.8385 382.755 17.3253 382.755 18.0586V65.1719C382.755 65.902 383.242 66.3919 383.975 66.3919H392.098C392.829 66.3919 393.318 65.9052 393.318 65.1719V47.5447C395.999 52.1751 400.468 54.6942 406.072 54.6942C416.55 54.6942 424.025 46.6534 424.025 35.2812C424.025 23.909 416.55 15.865 406.072 15.865ZM402.984 45.5155C397.38 45.5155 393.318 41.0463 393.318 35.2812C393.318 29.5161 397.38 25.0469 402.984 25.0469C408.995 25.0469 413.139 29.2696 413.139 35.2812C413.139 41.2929 408.995 45.5155 402.984 45.5155Z"
            fill="#011638"
          />
          <path
            d="M469.106 52.5007V18.0586C469.106 17.3285 469.592 16.8385 470.326 16.8385H478.449C479.179 16.8385 479.669 17.3253 479.669 18.0586V23.4191C481.455 18.7065 484.95 16.0262 489.821 16.0262C490.715 16.0262 491.85 16.1084 492.666 16.434C493.56 16.7595 493.721 17.3285 493.478 18.0586L491.367 24.8825C491.123 25.6126 490.554 25.856 489.824 25.5336C488.93 25.2081 488.117 25.0469 487.062 25.0469C483.244 25.0469 479.669 27.5659 479.669 34.3899V52.5038C479.669 53.234 479.182 53.7239 478.449 53.7239H470.326C469.592 53.7207 469.106 53.2308 469.106 52.5007Z"
            fill="#011638"
          />
          <path
            d="M66.0693 1.09512H47.7468C47.0925 1.09512 46.5141 1.52181 46.3213 2.15079L41.0018 19.5567C40.7616 20.3406 40.041 20.8748 39.2224 20.8748H25.1984C24.5441 20.8748 23.9657 21.3014 23.7729 21.9273L19.231 36.7794C18.9908 37.5601 18.2701 38.0942 17.4515 38.0942H5.92759C5.27333 38.0942 4.69492 38.5209 4.50212 39.1499L0.462746 52.3616C0.273104 52.9874 0.737727 53.6196 1.39199 53.6196H13.9336C14.5721 53.6196 15.1347 53.2024 15.3212 52.5924L19.1045 40.2182C19.3226 39.5071 19.9769 39.0235 20.7197 39.0235H34.8985C35.537 39.0235 36.0996 38.6063 36.2861 37.9963L40.8723 22.9956C41.0903 22.2844 41.7446 21.8008 42.4874 21.8008H60.0861C60.7246 21.8008 61.2872 21.3836 61.4737 20.7736L67.0808 2.46053C67.2862 1.78098 66.7805 1.09512 66.0693 1.09512Z"
            fill="#011638"
          />
          <path
            d="M29.5411 53.6196H21.2791C20.6248 53.6196 20.1602 52.9875 20.3498 52.3648L22.5939 45.0193C22.7867 44.3935 23.362 43.9668 24.0162 43.9668H32.2499C32.9041 43.9668 33.3687 44.599 33.1791 45.2216L30.9255 52.5924C30.739 53.2024 30.1764 53.6196 29.5411 53.6196Z"
            fill="#011638"
          />
          <path
            d="M49.4631 53.6196H37.7716C37.1174 53.6196 36.6527 52.9875 36.8424 52.3648L39.0865 45.0193C39.2793 44.3935 39.8545 43.9668 40.5088 43.9668H52.1718C52.826 43.9668 53.2907 44.599 53.101 45.2216L50.8474 52.5924C50.6641 53.2024 50.1015 53.6196 49.4631 53.6196Z"
            fill="#011638"
          />
          <path
            d="M53.9006 39.0203H42.2345C41.5803 39.0203 41.1156 38.3882 41.3053 37.7655L44.3522 27.7967C44.545 27.1709 45.1202 26.7442 45.7745 26.7442H57.4122C58.0665 26.7442 58.5311 27.3763 58.3414 27.999L55.285 37.9963C55.1017 38.6063 54.5391 39.0203 53.9006 39.0203Z"
            fill="#011638"
          />
        </g>
        <defs>
          <clipPath id="clip0_10_1067">
            <rect
              width="493.16"
              height="65.3101"
              fill="white"
              transform="translate(0.419983 0.0824585)"
            />
          </clipPath>
        </defs>
      </svg>
      <span className="sr-only">DataSnipper Logo</span>
    </div>
  )
}

export function LogoIcon({
  height = 48,
  width = 48,
  className,
}: {
  height?: number
  width?: number
  className?: string
}) {
  return (
    <svg
      className={cn('rounded-lg', className)}
      width={height}
      height={width}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="48" height="48" fill="#011638" />
      <path
        d="M40.2607 10.8221H30.9007C30.5661 10.8221 30.2697 11.0418 30.1727 11.3612L27.452 20.2536C27.332 20.6547 26.9616 20.928 26.5452 20.928H19.3796C19.0449 20.928 18.7486 21.1452 18.6515 21.4645L16.3294 29.0541C16.2094 29.4527 15.839 29.726 15.4226 29.726H9.53427C9.19962 29.726 8.90329 29.9457 8.80622 30.265L6.74212 37.0167C6.64505 37.3361 6.88262 37.6579 7.21727 37.6579H13.6267C13.9511 37.6579 14.2398 37.4459 14.3343 37.1343L16.2681 30.8091C16.378 30.4464 16.7126 30.2011 17.0932 30.2011H24.3355C24.6624 30.2011 24.9511 29.9866 25.0456 29.6749L27.3907 22.0112C27.5006 21.6484 27.8352 21.4006 28.2133 21.4006H37.2054C37.5324 21.4006 37.821 21.1861 37.9156 20.8744L40.7767 11.5195C40.8814 11.1721 40.6234 10.8221 40.2607 10.8221Z"
        fill="#FEFDFF"
      />
      <path
        d="M21.5971 37.6579H17.3769C17.0423 37.6579 16.8047 37.336 16.9018 37.0167L18.0488 33.264C18.1459 32.9447 18.4422 32.7275 18.7743 32.7275H22.9817C23.3163 32.7275 23.5539 33.0494 23.4568 33.3687L22.3047 37.1342C22.2102 37.4458 21.9215 37.6579 21.5971 37.6579Z"
        fill="#FEFDFF"
      />
      <path
        d="M31.7743 37.6577H25.8017C25.4671 37.6577 25.2295 37.3359 25.3266 37.0165L26.4736 33.2639C26.5707 32.9445 26.867 32.7249 27.2017 32.7249H33.1589C33.4936 32.7249 33.7311 33.0493 33.6341 33.3686L32.482 37.134C32.3874 37.4457 32.0988 37.6577 31.7743 37.6577Z"
        fill="#FEFDFF"
      />
      <path
        d="M34.0428 30.1986H28.083C27.7483 30.1986 27.5108 29.8767 27.6078 29.5574L29.1636 24.4636C29.2632 24.1443 29.557 23.9246 29.8916 23.9246H35.8387C36.1708 23.9246 36.4109 24.249 36.3138 24.5683L34.7504 29.6749C34.6559 29.9866 34.3672 30.1986 34.0428 30.1986Z"
        fill="#FEFDFF"
      />
    </svg>
  )
}
