import { Icon } from '@datasnipper/icons'
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from '@datasnipper/ui/collapsible'
import { Divider } from '@datasnipper/ui/divider'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@datasnipper/ui/select'
import { cn } from '@datasnipper/utils/cn'
import { Link } from '@remix-run/react'
import { Fragment } from 'react'
import resolveConfig from 'tailwindcss/resolveConfig'

import tailwindConfig from '../../../tailwind.config'
import { SubMenuItem } from '../navigation/navigation'

import { useMediaQuery } from '#app/hooks/useMediaQuery/useMediaQuery'
import { isValidIcon } from '#app/utils/isValidIcon.js'
import { ImageAttributes, LinkType } from '#types/shared'

export type FooterNavigationItem = {
  title: string
  items: SubMenuItem[]
}[]

type Props = {
  title: string
  subtitle: string
  primaryActionSlot: React.ReactNode
  secondaryActionSlot: React.ReactNode
  backgroundImage?: string
  backgroundImageSmall?: string
  items: FooterNavigationItem[]
  logo: ImageAttributes
  securityLogos?: ImageAttributes[]
  securityLinks?: LinkType[]
  socials?: {
    icon: string
    url: string
  }[]
}

export function FooterMenuItems({ items, logo }: Partial<Props>) {
  if (!items || items.length === 0) return null

  return (
    <div className={cn(`container grid auto-cols-fr grid-flow-col`)}>
      <div>
        {logo ? (
          <img
            loading="lazy"
            className="hidden w-10/12 lg:block"
            alt={logo.alt}
            src={logo.src}
          />
        ) : null}
      </div>

      {items.map((section, index) => {
        if (!section) return null

        return (
          <div key={index} className="flex flex-col gap-4">
            {section.map((item, index) => (
              <div className="mb-2" key={index}>
                <p className="pb-3 font-display text-xs font-semibold tracking-widest text-action-200">
                  {item.title.toUpperCase()}
                </p>
                <div className="flex flex-col gap-3 text-sm font-light">
                  {item.items.map((link, index) => (
                    <Link
                      reloadDocument
                      key={index}
                      to={link.href}
                      className="text-neutral-100 hover:text-primary-foreground"
                    >
                      {link.label}
                    </Link>
                  ))}
                </div>
              </div>
            ))}
          </div>
        )
      })}
    </div>
  )
}

export function FooterMobileMenuItems({ items, logo }: Partial<Props>) {
  if (!items || items.length === 0) return null

  return (
    <nav>
      {logo ? (
        <div className="mb-10 block w-full lg:hidden">
          <img
            loading="lazy"
            className="ml-4 w-32 md:ml-8 md:w-48"
            alt={logo.alt}
            src={logo.src}
          />
        </div>
      ) : null}
      {items.map(section => {
        if (!section || section.length === 0) return null

        return section.map((item, index) => (
          <Collapsible key={index} className="mt-4 w-screen px-4 md:px-8">
            <CollapsibleTrigger className="flex min-w-full flex-wrap items-center justify-between py-3">
              <>
                <p className="font-display text-body-md tracking-widest text-action-300">
                  {item.title.toUpperCase()}
                </p>
                <Icon name="plus" className="size-5 text-action-200" />
              </>
            </CollapsibleTrigger>
            <CollapsibleContent>
              <div className="flex flex-col gap-3 text-sm">
                {item.items.map((link, index) => (
                  <Link
                    reloadDocument
                    key={index}
                    to={link.href}
                    className="py-2 text-neutral-100 hover:underline"
                  >
                    {link.label}
                  </Link>
                ))}
              </div>
            </CollapsibleContent>
            <Divider className="my-3 bg-muted/25 data-[state=open]:bg-accent" />
          </Collapsible>
        ))
      })}
    </nav>
  )
}

export function Footer({
  title,
  subtitle,
  primaryActionSlot,
  secondaryActionSlot,
  backgroundImage,
  backgroundImageSmall,
  items,
  socials,
  logo,
  securityLogos,
  securityLinks,
}: Props) {
  const { md } = resolveConfig(tailwindConfig).theme.screens

  const minWidthReached = useMediaQuery(`(min-width: ${md})`)

  const backgroundStyles = {
    container: (minWidthReached?: boolean) => ({
      backgroundImage: minWidthReached ? `url('${backgroundImage}')` : 'none',
      backgroundPosition: 'top right',
      backgroundSize: '72rem',
    }),
  }

  return (
    <footer className="flex flex-col items-center justify-center bg-secondary text-primary-foreground">
      <div className="w-screen bg-gradient-to-b from-primary/35 to-secondary">
        <div
          style={backgroundStyles.container(minWidthReached)}
          className={cn(
            `bg-contain bg-right bg-no-repeat`,
            `flex place-content-start lg:place-content-center`,
          )}
        >
          <div
            className={cn(
              `container flex w-full flex-col pt-5 md:flex-row md:py-32`,
            )}
          >
            <div className="flex flex-col gap-5 text-balance py-20 text-center md:items-start md:justify-start md:text-left">
              <h2
                className={cn(
                  `mb-2 text-primary-foreground`,
                  `text-3xl font-medium sm:text-[2.5rem]`,
                )}
              >
                {title}
              </h2>
              <p className="max-w-screen-sm text-lg font-normal text-neutral-100 md:max-w-96 lg:max-w-screen-sm">
                {subtitle}
              </p>
              <div className="flex  w-full flex-col items-start justify-start gap-5 md:flex-row">
                <div className="w-full md:w-auto">{primaryActionSlot}</div>
                <div className="w-full md:w-auto">{secondaryActionSlot}</div>
              </div>
            </div>
            <div>
              <img
                loading="lazy"
                className="visible mx-4 w-full md:hidden"
                src={backgroundImageSmall}
                alt="footer background"
              />
            </div>
          </div>
        </div>
      </div>

      <div aria-label="Footer" className="flex items-center justify-center">
        <div className="block lg:hidden">
          <FooterMobileMenuItems logo={logo} items={items} />
        </div>

        <div className="hidden lg:block">
          <FooterMenuItems items={items} logo={logo} socials={socials} />
        </div>
      </div>

      <div className="container flex items-center justify-center">
        <div className="mt-20 flex w-full flex-col items-center justify-between border-t border-muted/25 py-5 text-sm text-muted lg:mt-8 lg:flex-row lg:items-center">
          {/* <div className="order-1 flex w-full items-start justify-between gap-5 py-5 text-sm text-primary-foreground lg:order-1 lg:w-auto lg:flex-col lg:justify-start"> */}
          {/* Temporarily hiding the selector until we
            implement localization through Contentstack */}
          <Select>
            <SelectTrigger
              aria-label="Change language"
              className="hidden w-40 border-none bg-secondary p-0"
            >
              <SelectValue placeholder="English (United States)" />
            </SelectTrigger>
            <SelectContent className="bg-secondary text-primary-foreground">
              <SelectItem className="hover:cursor-pointer" value="en-us">
                English (United States)
              </SelectItem>
              <SelectItem className="hover:cursor-pointer" value="jp">
                Japanese
              </SelectItem>
            </SelectContent>
          </Select>
          {/* </div> */}
          <div className="order-2 flex items-center gap-10">
            <div className="flex gap-2 py-5 text-muted lg:order-2">
              {socials?.map(social =>
                isValidIcon(social.icon) ? (
                  <Link
                    reloadDocument
                    aria-label={social.icon}
                    key={social.icon}
                    to={social.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Icon
                      name={social.icon}
                      size="lg"
                      className="hover:text-action-200"
                    />
                  </Link>
                ) : null,
              )}
            </div>
            <div className="flex gap-5">
              {securityLogos?.map((logo, index) => (
                <img
                  loading="lazy"
                  className="h-8"
                  key={index}
                  src={logo.src}
                  alt={logo.alt}
                />
              ))}
            </div>
          </div>
          <div className="order-3 flex w-full  flex-col items-center gap-5 py-5 lg:order-3 lg:w-auto lg:flex-row lg:justify-between">
            <div className="flex items-center gap-2">
              {securityLinks?.map((link, index) => (
                <Fragment key={index}>
                  <Link
                    reloadDocument
                    to={link.href}
                    className="border-muted pr-2 last:border-none hover:text-action-200"
                  >
                    {link.children}
                  </Link>
                </Fragment>
              ))}
            </div>

            <span>&copy; Datasnipper {new Date().getFullYear()}</span>
          </div>
        </div>
      </div>
    </footer>
  )
}
