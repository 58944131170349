import { Icon } from '@datasnipper/icons'
import { Badge } from '@datasnipper/ui/badge'
import { Button } from '@datasnipper/ui/button'
import { cn } from '@datasnipper/utils/cn'
import { Link } from '@remix-run/react'
import { useEffect, useState } from 'react'
import resolveConfig from 'tailwindcss/resolveConfig'

import tailwindConfig from '../../tailwind.config'

import { mapAsset } from './mappers'

import {
  AnnouncementBanner,
  AnnouncementBannerProps,
} from '#app/components/announcement-banner/announcement-banner.js'
import {
  Navigation,
  SubMenuItem,
} from '#app/components/navigation/navigation.tsx'
import {
  Header,
  HeaderItemsSubMenuItemItems,
} from '#app/graphql/@generated/api.ts'
import { useMediaQuery } from '#app/hooks/useMediaQuery/useMediaQuery.js'
import { isValidProperty, getValidatedProperty } from '#app/utils/shared'
import { BadgeAttributes } from '#types/shared'

const mapSubMenuItems = (
  items: Array<Omit<
    HeaderItemsSubMenuItemItems,
    '__typename'
  > | null> | null = [],
): SubMenuItem[] =>
  (items ?? []).map(item => ({
    label: item?.title ?? '',
    title: item?.title ?? '',
    href: item?.url ?? '',
    badge: item?.badge ?? '',
    icon: item?.iconConnection?.edges?.[0]?.node?.url
      ? mapAsset(item?.iconConnection?.edges?.[0])
      : undefined,
    items: mapSubMenuItems(item?.items),
    component: item?.componentConnection?.edges?.[0]?.node ?? null,
  }))

function mapNavigationItems(items: Header['items']) {
  return (items ?? []).map(item => {
    return {
      title: item?.title ?? '',
      link: item?.link ?? undefined,
      singleColumn: item?.single_column ?? true,
      // @ts-expect-error - mapSubMenuItems returns SubMenuItem[] but MenuItemType is expected
      subMenuItem: mapSubMenuItems(item?.sub_menu_item ?? []),
    }
  })
}

export function CsNavigation({ entry }: { entry: Header | null | undefined }) {
  const [showAnnouncement, setShowAnnouncement] = useState(false)

  const { md } = resolveConfig(tailwindConfig).theme.screens

  const minWidthReached = useMediaQuery(`(min-width: ${md})`)

  // Update `showAnnouncement` based on `minWidthReached`
  useEffect(() => {
    if (minWidthReached) {
      setShowAnnouncement(entry?.notification_bar?.show_announcement ?? true)
    } else {
      setShowAnnouncement(false)
    }
  }, [minWidthReached, entry])

  const announcementBadge = (
    <Badge
      variant={getValidatedProperty(
        entry?.notification_bar?.badge?.variant,
        'highlight',
        isValidProperty<BadgeAttributes['variant']>,
      )}
    >
      <span className="py-1">
        {entry?.notification_bar?.badge?.text ?? 'NEWS'}
      </span>
    </Badge>
  )

  return entry ? (
    <div className="bg-neutral-800">
      {showAnnouncement ? (
        <AnnouncementBanner
          onHideAnnouncement={() => setShowAnnouncement(false)}
          background={getValidatedProperty(
            entry?.notification_bar?.background,
            'action',
            isValidProperty<AnnouncementBannerProps['background']>,
          )}
          link={{
            children: (
              <p className="flex gap-1 font-display text-sm">
                {entry?.notification_bar?.link?.title ?? ''}
                <Icon name="arrow-right" size="md" />
              </p>
            ),
            href: entry?.notification_bar?.link?.href ?? '',
          }}
          message={entry?.notification_bar?.announcement_text ?? ''}
          slot={announcementBadge}
        />
      ) : null}
      <Navigation
        items={mapNavigationItems(entry?.items ?? [])}
        className={cn(showAnnouncement ? 'top-12' : 'top-0')}
        actionButton={
          <Button className="w-full py-2 text-sm lg:w-36" asChild>
            <Link reloadDocument to={entry.action_button?.href ?? '#'}>
              {entry.action_button?.title ?? ''}
            </Link>
          </Button>
        }
      />
    </div>
  ) : null
}
