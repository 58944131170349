import { cn } from '@datasnipper/utils/cn'

export function Divider({
  spacing = true,
  className,
}: {
  spacing?: boolean
  className?: string
}) {
  return (
    <hr
      className={cn(
        'h-px border-none bg-divider',
        spacing && 'my-6',
        className,
      )}
    />
  )
}
